body {
  color: #363232;
  margin: 0;
}

header {
  text-align: right;
  width: 100%;
  position: absolute;
  top: 1rem;
  right: 5rem;
}

.blue {
  background-color: #0392cf4d;
}

.red {
  background-color: #ee40354d;
}

.yellow {
  background-color: #fff04780;
}

.green {
  background-color: #52b56c80;
}

canvas {
  z-index: -5;
  position: absolute;
}

a {
  color: #000;
  background: linear-gradient(#363232b3 0% 100%) 0 100% / 4px 4px repeat-x;
  text-decoration: none;
}

a:hover {
  color: #fff;
  background-size: 4px 50px;
  transition: background-size .8s, color .4s linear;
}

html, body, #root {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: Afacad Flux;
}

.App {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-rows: 1fr 1.5fr .5fr;
  grid-template-columns: 1fr 1fr 1.5fr;
  align-items: center;
  height: 100vh;
  padding: 0 5%;
  font-size: 3.5vw;
  display: grid;
}

.App .item-top {
  grid-area: 1 / 1 / auto / 4;
}

@media screen and (width <= 550px) {
  .App .item-top {
    text-align: center;
    grid-column: 1 / 4;
  }
}

.App .item-mid {
  text-align: right;
  color: #000;
  grid-area: 2 / 2 / auto / 4;
  align-self: end;
  font-size: 1.4rem;
  line-height: 1.75rem;
}

@media screen and (width <= 550px) {
  .App .item-mid {
    text-align: center;
    grid-column: 1 / 4;
    font-size: 4vw;
  }
}

.App .item-mid .small {
  font-size: 1rem;
}

.App .item-bottom {
  color: #000;
  text-align: center;
  grid-area: 3 / 1 / auto / 4;
  align-self: center;
  padding: 0 5%;
  font-family: Lato;
  font-size: 1rem;
}

.App .item-bottom span {
  font-size: .8rem;
}

.App .small {
  font-size: 1.5rem;
  display: block;
}

.App .tiny {
  font-size: 1rem;
}

@media screen and (width <= 550px) {
  .App {
    padding: 0;
  }
}

.floating {
  opacity: .6;
  letter-spacing: -5px;
  margin-top: -4rem;
  font-family: Mandali;
  font-size: 8rem;
}

.floating .letter .hover {
  animation: 3s hoveranimation;
}

@keyframes hoveranimation {
  50% {
    transform: translateY(-50px);
  }
}
/*# sourceMappingURL=index.0c30a3bb.css.map */
