$mainColor: #363232;
$break-large: 550px;
body {
  margin: 0;
  color: $mainColor;
}
header {
  position: absolute;
  top: 1rem;
  right: 5rem;
  width: 100%;
  text-align: right;
}
.blue {
  background-color: rgba(3, 146, 207, 0.3);
}
.red {
  background-color: rgba(238, 64, 53, 0.3);
}
.yellow {
  background-color: rgba(255, 240, 71, 0.5);
}
.green {
  background-color: rgba(82, 181, 108, 0.5);
}
canvas {
  position: absolute;
  z-index: -5;
}
a {
  background: linear-gradient(
    to bottom,
    rgba($mainColor, 0.7) 0%,
    rgba($mainColor, 0.7) 100%
  );
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 4px 4px;
  color: #000;
  text-decoration: none;
  &:hover {
    background-size: 4px 50px;
    color: white;
    transition: background-size 0.8s, color 0.4s linear;
  }
}
html,
body,
#root {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: 'Afacad Flux';
}
.App {
  height: 100vh;
  padding: 0 5%;
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr;
  grid-template-rows: 1fr 1.5fr 0.5fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  font-size: 3.5vw;
  align-items: center;
  .item-top {
    grid-column: 1 / 4;
    grid-row: 1;
    @media screen and (max-width: $break-large) {
      grid-column: 1 / 4;
      text-align: center;
    }
  }
  .item-mid {
    grid-column: 2 / 4;
    grid-row: 2;
    @media screen and (max-width: $break-large) {
      grid-column: 1 / 4;
      text-align: center;
    }
    align-self: end;
    font-size: 1.4rem;
    line-height: 1.75rem;
    text-align: right;
    color: black;
    @media screen and (max-width: $break-large) {
      font-size: 4vw;
    }
    .small {
      font-size: 1rem;
    }
  }
  .item-bottom {
    grid-column: 1 / 4;
    grid-row: 3;
    align-self: center;
    color: black;
    font-size: 1rem;
    font-family: 'Lato';
    text-align: center;
    padding: 0 5%;
    span {
      font-size: 0.8rem;
    }
  }
  .small {
    display: block;
    font-size: 1.5rem;
  }
  .tiny {
    font-size: 1rem;
  }
  @media screen and (max-width: $break-large) {
    padding: 0;
  }
}

.floating {
	margin-top: -4rem;
	font-family: Mandali;
	font-size: 8rem;
	opacity: 0.6;
	letter-spacing: -5px;
	
	.letter {
		.hover {
			-webkit-animation: hoveranimation ease 3s;
			animation: hoveranimation ease 3s;
		}
		
		@keyframes hoveranimation {
			50% {
				transform: translateY(-50px);
			}
		}
	}
}